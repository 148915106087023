import React, { useState, useEffect, useRef } from 'react';
import Advert from "./Advert";
import useInterval from '@use-it/interval';
import options from "../../options";
import moment from 'moment';
import useDebugOption from "../../hooks/useDebugOption";
import {usePageVisibility} from "react-page-visibility";

const totalPadding = options.advertRain.columnPadding * (options.advertRain.columnCount + 1);
const availableWidth = 100 - totalPadding;
const columnWidth = availableWidth / options.advertRain.columnCount;
const horizontalOffsets = [...new Array(options.advertRain.columnCount)].map((_, index) => {
    return (columnWidth * index) + (options.advertRain.columnPadding * (index + 1)) + (columnWidth / 2);
});

const sortAdverts = (a, b) => {
    return moment.utc(b.timestamp).unix() - moment.utc(a.timestamp).unix();
};

function AdvertRain(props) {
    const { go, adverts } = props;

    useEffect(() => {
        if(!(!previousGo && go)) return;
        setIntervalCount(0);
        setVisibleAdverts([]);
        setAdvertQueue(adverts.sort(sortAdverts));
        setShownAdvertIds([]);
        setRemainingHorizontalOffsets(horizontalOffsets);
        setPreviousHorizontalOffset(null);
        setLogoCountdown(options.advertRain.logoFrequency - 1);
        setLastShownAdvertIds([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [go]);

    useEffect(() => {
        if(!go) return;
        const workingAdverts = [...advertQueue, ...adverts].reduce((previousValue, currentValue) => {
            return previousValue.some(x => x.id === currentValue.id) || shownAdvertIds.includes(currentValue.id)
                ? previousValue : [...previousValue, currentValue];
        }, []).sort(sortAdverts);
        setAdvertQueue(workingAdverts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adverts]);

    const previousGo = usePrevious(go);
    const isPageVisible = usePageVisibility();

    useInterval(() => {
        if(!go || !isPageVisible) return;

        const isLogo = logoCountdown === 0;

        const localRemainingHorizontalOffsets = remainingHorizontalOffsets.length === 0
            ? [...horizontalOffsets.filter(x => x !== previousHorizontalOffset)]
            : [...remainingHorizontalOffsets];
        const offsetIndex = Math.round(Math.random() * (localRemainingHorizontalOffsets.length - 1));
        const horizontalOffset = localRemainingHorizontalOffsets[offsetIndex];

        // console.log('getting a new advert');
        // console.log('advert queue', advertQueue.map(x => x.id ? x.id.substring(0, 8) : 'ID Error'));
        // console.log('shown advert IDs', shownAdvertIds.map(x => x ? x.substring(0, 8) : 'ID Error'));
        let queueReset = false;
        let nextAdvert = [...advertQueue.filter(x => !shownAdvertIds.includes(x.id) && !lastShownAdvertIds.includes(x.id))][0];
        if(!nextAdvert) {
            console.warn("No more adverts to display - resetting advert queue and shown advert list");
            queueReset = true;
            const newAdvertQueue = adverts.sort(sortAdverts);
            setAdvertQueue(newAdvertQueue);
            setShownAdvertIds([]);
            nextAdvert = newAdvertQueue[0];
        }

        if(nextAdvert){
            // Delete first visible advert after 12s / 12 loops
            setVisibleAdverts([...(intervalCount >= options.advertRain.deleteAfter ? visibleAdverts.slice(1) : visibleAdverts), {
                key: Math.random(),
                queueReset,
                horizontalOffset,
                ...(isLogo ? { logo: true } : { ...nextAdvert })
            }]);

            if(!isLogo) {
                setLastShownAdvertIds([nextAdvert.id, ...lastShownAdvertIds].splice(0, 5));
                if(!queueReset) {
                    setShownAdvertIds([...shownAdvertIds, nextAdvert.id]);
                    setAdvertQueue([...advertQueue.slice(1)]);
                }
            }

            setRemainingHorizontalOffsets(remainingHorizontalOffsets.length === 0
                ? [...horizontalOffsets].filter(x => x !== horizontalOffset)
                : localRemainingHorizontalOffsets.filter(x => x !== horizontalOffset));
            setPreviousHorizontalOffset(horizontalOffset);
        } else {
            console.error('No adverts in queue to display');
        }

        setIntervalCount(intervalCount + 1);
        setLogoCountdown(logoCountdown === 0 ? options.advertRain.logoFrequency - 1 : logoCountdown - 1);
    }, options.advertRain.dropFrequency);

    const [intervalCount, setIntervalCount] = useState(0);
    const [visibleAdverts, setVisibleAdverts] = useState([]);
    const [advertQueue, setAdvertQueue] = useState(adverts);
    const [shownAdvertIds, setShownAdvertIds] = useState([]);
    const [remainingHorizontalOffsets, setRemainingHorizontalOffsets] = useState(horizontalOffsets);
    const [previousHorizontalOffset, setPreviousHorizontalOffset] = useState(null);
    const [logoCountdown, setLogoCountdown] = useState(options.advertRain.logoFrequency - 1);
    const [lastShownAdvertIds, setLastShownAdvertIds] = useState([]);

    const showQueueSize = useDebugOption(options.debug.showAdvertRainQueueSize);

    return (
        <div style={{ position: 'fixed', left: 0, right: 0, width: '100vw', height: '100vh' }}>
            {/*Counter*/}
            {showQueueSize && <div style={{
                position: 'fixed',
                left: 0,
                bottom: 0,
                padding: 20,
                fontSize: '5vw',
                opacity: 1,
                zIndex: 99999,
                backgroundColor: advertQueue.length >= 10 ? 'lime' : advertQueue.length > 5 ? 'yellow' : 'red'
            }}>
                {advertQueue.length}
            </div>}
            {visibleAdverts.map(advert => <Advert key={advert.key} {...advert}/>)}
        </div>
    );
}

// From: https://usehooks.com/usePrevious/
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export default AdvertRain;
