import React, {Fragment} from 'react';
import Logo from "../Logo";
import BoltHalfBlue from "../BoltHalfBlue";
import posed, {PoseGroup} from "react-pose";

const LogoContainer = posed.div({
    preEnterPose: {
        y: '-50vh',
        opacity: .5,
    },
    enter: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            stiffness: 50,
            duration: 1000,
        }
    },
    exit: {
        y: '70vh',
        opacity: .4,
        transition: {
            ease: 'easeOut',
            duration: 1000
        }
    }
});

const BoltContainer = posed.div({
    preEnterPose: {
        y: '40vh',
        opacity: 0,
    },
    enter: {
        y: 0,
        opacity: 1,
        transition: {
            ease: 'easeIn',
            duration: 2000
        }
    },
    exit: {
        y: '70vh',
        opacity: 1,
        transition: {
            ease: 'easeOut',
            duration: 2000
        }
    }
});

function StageOne(props) {
    const {visible} = props;

    return (
        <Fragment>
            <PoseGroup preEnterPose="preEnterPose" style={{zIndex: 2}}>
                {visible && [
                    <LogoContainer key="logo">
                        <Logo/>
                    </LogoContainer>
                ]}
            </PoseGroup>
            <div style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                padding: 0,
                boxSizing: 'border-box'
            }}>
                <PoseGroup preEnterPose="preEnterPose" style={{zIndex: 2, display: 'flex'}}>
                    {visible && [
                        <BoltContainer key="bolt">
                            <BoltHalfBlue/>
                        </BoltContainer>
                    ]}
                </PoseGroup>
            </div>
        </Fragment>
    );
}

export default StageOne;
