import React, {useState, useEffect} from 'react';
import './advert.css';
import options from "../../options";
import LogoImage from '../../images/AgileLogo_RGB_Swoosh_White_With_Tagline.png';
import useSecondsAgo from "../../hooks/useSecondsAgo";
import useDebugOption from "../../hooks/useDebugOption";
import moment from "moment";
import {useMediaQuery} from "react-responsive";

const totalPadding = options.advertRain.columnPadding * (options.advertRain.columnCount + 1);
const availableWidth = 100 - totalPadding;
const columnWidth = availableWidth / options.advertRain.columnCount;
const minAdvertWidth = columnWidth * options.advertRain.minAdvertWidthFactor;
const widthOptions = [...new Array(options.advertRain.advertSizeCount)].map((_, index) => {
    return minAdvertWidth + ((columnWidth - minAdvertWidth) / (options.advertRain.advertSizeCount - 1)) * index;
});

function Advert(props) {
    const {
        id,
        logo,
        image,
        timestamp,
        channel,
        location,
        horizontalOffset,
        queueReset
    } = props;

    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const secondsAgo = useSecondsAgo(timestamp);
    const [width, setWidth] = useState(logo ? columnWidth
        : (widthOptions[Math.round(Math.random() * (widthOptions.length - 1))] ));

    useEffect(() => {
        if(logo || !options.advertRain.sizeAdvertsByRecency.enabled) return;

        const initialSecondsAgo = Math.round(moment.duration(moment().utc().diff(moment.utc(timestamp))).asSeconds());
        const thresholdMatches = options.advertRain.sizeAdvertsByRecency.thresholds.filter(threshold => {
            return (!threshold.floor && initialSecondsAgo < threshold.ceiling)
                || (initialSecondsAgo >= threshold.floor && initialSecondsAgo < threshold.ceiling)
                || (initialSecondsAgo >= threshold.floor && !threshold.ceiling);
        });

        if(thresholdMatches.length !== 1) {
            console.error(`Unable to find a width recency threshold for an advert which aired ${initialSecondsAgo}` +
            ` seconds ago. Found ${thresholdMatches.length} matches`, thresholdMatches);

            setWidth(minAdvertWidth);
            return;
        }

        setWidth(minAdvertWidth + ((columnWidth - minAdvertWidth) * thresholdMatches[0].widthFactor));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showAdvertId = useDebugOption(options.debug.showAdvertId);

    const { textStyle } = options.advertRain;
    if(!['bottom', 'overlay', 'below'].includes(textStyle.position))
        console.error('Invalid options.advertRain.textStyle.position - must be \'bottom\', \'overlay\' or \'below\'\nDefaulting to \'bottom\'');
    if(!['none', 'full', 'short'].includes(textStyle.secondsAgo))
        console.error('Invalid options.advertRain.textStyle.secondsAgo - must be \'none\', \'full\' or \'short\'\nDefaulting to \'full\'');

    if(!id || !image || !timestamp || !channel || !location)
        return <div />;

    return (
        <div style={{
            position: 'fixed',
            top: '-25vw',
            left: `${horizontalOffset - (width / 2)}vw`,
            width: `${width}vw`,
            animation: isPortrait ? 'drop 24s linear' : 'drop 12s linear',
            padding: logo ? '2vw' : null,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            overflow: 'hidden'
        }}>
            <img alt="" src={logo ? LogoImage : image} width={'100%'}/>
            {/*Text background overlay*/}
            {(!logo && showAdvertId) && <div style={{
                position: 'absolute',
                fontSize: '2vw',
                width: '100%',
                color: queueReset ? 'red' : 'aqua',
                top: 0,
                left: 0,
                background: 'black'
            }}>
                <div>{id && id.substring(0, 8)}</div>
            </div>}
            {!logo && <div style={{
                position: textStyle.position === 'below' ? 'unset' : 'absolute',
                display: 'flex',
                justifyContent: 'center',
                color: textStyle.shadedText ? '#b8bcbd' : '#ecf0f1',
                textAlign: 'center',
                width: '100%',
                ...(textStyle.position === 'overlay' ? {
                    fontSize: '5vw',
                    top: 0,
                    left: 0,
                    height: '100%',
                    alignItems: 'center',
                    background: 'rgba(0, 0, 0, 0.4)'
                } : textStyle.position !== 'below' && {
                    fontSize: '1.2vw',
                    bottom: 0,
                    height: '30%',
                    alignItems: 'flex-end',
                    paddingBottom: '0.7vw',
                    background: 'linear-gradient(0deg, #404040, transparent)'
                }),
                ...(textStyle.fontSize ? { fontSize: `${textStyle.fontSize}vw` } : {}),
                ...(textStyle.position === 'below' ? { marginTop: '0.3vw' } : {})
            }}>
                <div>
                    {textStyle.secondsAgo !== 'none' &&
                    (textStyle.secondsAgo === 'short' ? `${secondsAgo}s ago`
                        : `${secondsAgo} ${Math.abs(secondsAgo) === 1 ? 'second' : 'seconds'} ago`)}

                    {textStyle.showChannel &&
                    (textStyle.secondsAgo === 'none' ? channel : ` on ${channel}`)}

                    {textStyle.showLocation && <div style={{
                        color: "#7f8c8d",
                        marginTop: 4
                    }}>{location}</div>}
                </div>
            </div>}
        </div>
    );
}

export default React.memo(Advert);
