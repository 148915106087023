import React, {Fragment, useEffect, useRef} from 'react';
import {MdAccessTime, MdSettingsRemote, MdLocationOn, MdTrendingDown, MdTrendingFlat, MdTrendingUp} from "react-icons/md";
import LogoImage from '../../images/AdgileLogo_RGB_Orange_Slate_Grey.png';
import useSecondsAgo from "../../hooks/useSecondsAgo";
import options from '../../options';

function Hero(props) {
    const {
        image,
        timestamp,
        channel,
        location,
        visible,
        tvcUrl
    } = props;

    useEffect(() => {
        if(visible && tvcUrl && videoRef.current) {
            videoRef.current.play();
        }
        if(!visible)
            setTimeout(() => {
                if(videoRef.current) {
                    videoRef.current.pause();
                }
            }, 1000);
    });

    const videoRef = useRef(null);

    const secondsAgo = useSecondsAgo(timestamp);

    const stats = [
        {name: 'Time', icon: MdAccessTime, value: `${secondsAgo} seconds ago`},
        {name: 'Channel', icon: MdSettingsRemote, value: channel},
        {name: 'Region', icon: MdLocationOn, value: location}
    ];

    const videoBlurStyle = {
        position: 'absolute',
        backdropFilter: `blur(${options.heroVideoBlur.blurRadius}px)`
    };
    const videoBlurThickness = options.heroVideoBlur.thickness;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ecf0f1',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}>
            <div style={{
                width: '50vw',
                maxHeight: '80vh',
                position: 'relative',
                borderRadius: 8,
                overflow: 'hidden'
            }}>
                <video
                    src={tvcUrl}
                    autoPlay={false}
                    width={'100%'}
                    muted
                    loop
                    preload="auto"
                    ref={videoRef}
                    style={{ display: !tvcUrl && 'none' }}
                />
                {tvcUrl && <Fragment>
                    <div style={{ ...videoBlurStyle, top: 0, left: 0, width: '100%', height: videoBlurThickness }} />
                    <div style={{ ...videoBlurStyle, bottom: 0, left: 0, width: '100%', height: videoBlurThickness }} />
                    <div style={{ ...videoBlurStyle, top: videoBlurThickness, left: 0, width: videoBlurThickness, height: `calc(100% - ${videoBlurThickness * 2}px)` }} />
                    <div style={{ ...videoBlurStyle, top: videoBlurThickness, right: 0, width: videoBlurThickness, height: `calc(100% - ${videoBlurThickness * 2}px)` }} />
                </Fragment>}
                <img
                    alt=""
                    src={image}
                    width={'100%'}
                    style={{ display: tvcUrl && 'none' }}
                />

                {/*<div style={{*/}
                {/*    position: 'absolute',*/}
                {/*    textAlign: 'center',*/}
                {/*    fontSize: '2.5vw',*/}
                {/*    width: '100%',*/}
                {/*    color: '#ffffff',*/}
                {/*    bottom: 0,*/}
                {/*    background: 'linear-gradient(0deg, #404040, transparent)',*/}
                {/*    height: '20%',*/}
                {/*    display: 'flex',*/}
                {/*    justifyContent: 'center',*/}
                {/*    alignItems: 'flex-end',*/}
                {/*}}>*/}
                {/*    <div style={{marginBottom: '1.5vw'}}>{secondsAgo} seconds ago on {channel} in {location}</div>*/}
                {/*</div>*/}
            </div>
            <div style={{
                width: '30vw',
                backgroundColor: '#ecf0f1',
                color: '#2c3e50',
                padding: '2vw 2vw 2vw 3vw',
                borderRadius: '0px 8px 8px 0px',
                marginLeft: visible ? 0 : '-35vw',
                transitionDuration: visible ? '1s' : '',
                transitionDelay: visible ? '0.5s' : '1s'
            }}>
                <div style={{
                    display: 'flex',
                    paddingTop: '1vw'
                }}>
                    <div>
                        {stats.map((stat, index) => <div key={stat.name} style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: index === stats.length - 1 ? '1vw' : '2vw',
                            height: '3vw'
                        }}>
                            {React.createElement(stat.icon, {size: '3vw'})}
                            <div style={{
                                fontSize: '2vw',
                                marginLeft: '1.5vw'
                            }}>{stat.name}</div>
                        </div>)}
                    </div>
                    <div style={{marginLeft: '2vw'}}>
                        {stats.map((stat, index) => <div key={stat.name} style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: index === stats.length - 1 ? '1vw' : '2vw',
                            height: '3vw'
                        }}>
                            <div style={{
                                fontSize: '2vw',
                            }}>
                                {stat.value}
                            </div>
                            <div style={{marginLeft: '0.5vw'}}>
                                {stat.trend === 'up' && <MdTrendingUp size='2.5vw' style={{color: '#2ecc71'}}/>}
                                {stat.trend === 'down' && <MdTrendingDown size='2.5vw' style={{color: '#e74c3c'}}/>}
                                {stat.trend === 'flat' && <MdTrendingFlat size='2.5vw' style={{color: '#3498db'}}/>}
                            </div>
                        </div>)}
                    </div>
                </div>
                <div>
                    <img alt="" src={LogoImage} style={{ width: '50%', marginTop: '2vw' }}/>
                </div>
            </div>
        </div>
    );
}

export default Hero;