import React, {useState, useEffect, useCallback} from 'react';
import './App.css';
import AdvertRain from "./components/AdvertRain/index";
import Hero from "./components/Hero";
import Copy from "./components/Copy";
import useInterval from '@use-it/interval';
import StageOne from "./components/stages/StageOne";
import axios from "axios";
import moment from 'moment';
import options from './options';
import * as signalR from '@aspnet/signalr';
import useDebugOption from "./hooks/useDebugOption";
import TellMeMoreButton from "./components/TellMeMoreButton";

const isDev = process.env.NODE_ENV === 'development';
const isUserFacing = ['#uf', '#showcase'].includes(window.location.hash.toLowerCase())
    ? window.location.hash.toLowerCase() === '#uf'
    : isDev
        ? options.debug.isUserFacing
        : !(window.location.hostname.toLowerCase() === options.showcaseLocation.hostname &&
            window.location.pathname.toLowerCase().startsWith(options.showcaseLocation.pathname));

// TODO: Tidy sample adverts
const sampleAdverts = [
    // { image: "https://adgile.blob.core.windows.net/matches/PER-032-STD-637088183895000000.jpg", channel: "Channel 7", location: "Perth" },
    // { image: "https://adgile.blob.core.windows.net/matches/CBR-034-STD-637088306590000000.jpg", channel: "Channel 7", location: "Perth" },
    // { image: "https://adgile.blob.core.windows.net/matches/OOL-091-STD-637088270825000000.jpg", channel: "Channel 7", location: "Perth" },
    // { image: "https://adgile.blob.core.windows.net/matches/BNE-012-STD-637088270370000000.jpg", channel: "Channel 7", location: "Perth" },
    // { image: "https://adgile.blob.core.windows.net/matches/ADL-011-STD-637088288780000000.jpg", channel: "Channel 7", location: "Perth" },
    // { image: "https://adgile.blob.core.windows.net/matches/AKL-310-STD-637088378530000000.jpg", channel: "Channel 7", location: "Perth" },
    // { image: "https://adgile.blob.core.windows.net/matches/AKL-303-STD-637088378610000000.jpg", channel: "Channel 7", location: "Perth" },
    // { image: "https://adgile.blob.core.windows.net/matches/CBR-073-STD-637088306590000000.jpg", channel: "Channel 7", location: "Perth" }
];

const sortAdverts = (a, b) => {
    return moment.utc(b.timestamp).unix() - moment.utc(a.timestamp).unix();
};

if (window.location.pathname.length === 3) {
    const pathRegion = window.location.pathname.substr(1).toLowerCase();
    if (['au', 'nz', 'gb', 'ca'].includes(pathRegion)) {
        options.region = pathRegion
    }
}

console.log(
    "+--- Adgile Media FoTV Showcase ---+\n" +
    "|                                  |\n" +
    "|  setRegion(region)               |\n" +
    "|  setDelayOverride(milliseconds)  |\n" +
    "|                                  |\n" +
    "|  User facing: " + isUserFacing.toString().padEnd(17, " ") + "  |\n" +
    "|  Current region: " + options.region.padEnd(14, " ") + "  |\n" +
    "|  Current delay override: " +
    (options.delayOverride ? options.delayOverride.toString().padEnd(6, " ") : "None  ") + "  |\n" +
    "|                                  |\n" +
    "+----------------------------------+\n");

const apiUrl = isDev ? options.apiUrl.dev : options.apiUrl.live;
const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${apiUrl}/streamHub`)
    .build();

window.setRegion = region => {
    options.region = region;
    console.log("Set region to " + region);

    hubConnection.invoke('subscribe', options.region).then(() =>
        console.log(`Subscribed to stream hub region ${options.region}`));
};

window.setDelayOverride = delay => {
    if(isNaN(delay)) {
        console.error("Specified delay is not a number");
        return;
    }

    options["delayOverride"] = Number(delay);
    console.log("Set delay override to " + delay);
};

function App() {
    const [stage, setStage] = useState(0);
    const [adverts, setAdverts] = useState(sampleAdverts);
    const [heroAd, setHeroAd] = useState(adverts[0]);
    const [advertsWithVideo, setAdvertsWithVideo] = useState([]);

    const stages = isUserFacing
        ? options.stages.map(stage => {
            return {...stage, ...options.userFacingStages.find(userFacingStage => userFacingStage.id === stage.id)}
        }) : options.stages;
    const stagesWithRain = stages.filter(x => x.showRain).map(y => y.id);
    const stagesWithHero = stages.filter(x => x.showHero).map(y => y.id);

    useInterval(() => {
        if(stage === 0) {
            const advertsWithRecentVideo = advertsWithVideo.filter(advert =>
                Math.round(moment.duration(moment().utc().diff(moment.utc(advert.timestamp))).asSeconds()) <= options.heroVideoExpiry);

            if (advertsWithRecentVideo.length >= 1) {
                setHeroAd(advertsWithRecentVideo[0]);
                setAdvertsWithVideo([...advertsWithRecentVideo.slice(1)]);
            } else {
                console.warn("No ads with videos were found - displaying a random hero ad instead");
                setHeroAd(adverts[0]);
                setAdvertsWithVideo(advertsWithRecentVideo);
            }
        }
        setStage( (stage === stages.length - 1) ? 0 : stage + 1);
    }, options.delayOverride || stages[stage].duration);

    const receiveAdverts = useCallback((newAdverts, transformNames = false) => {
        const incomingAdverts = newAdverts.map(match => {
            const {
                Image: image,
                ChannelName: channelName,
                RegionName: regionName,
                DateTimeCreatedUTC: dateTimeCreatedUtc,
                TvcUrl: tvcUrl
            } = match;

            return {
                id: transformNames ? match.ID : match.id,
                image: transformNames ? image : match.image,
                channel: transformNames ? channelName : match.channelName,
                location: transformNames ? regionName : match.regionName,
                timestamp: transformNames ? dateTimeCreatedUtc : match.dateTimeCreatedUTC,
                tvcUrl: transformNames ? tvcUrl : match.tvcUrl
            }
        });

        setAdverts(prevState =>
            // Required to prevent signature mismatch warning
            [...(

            [...incomingAdverts, ...prevState].reduce((previousValue, currentValue) => {
                return previousValue.some(x => x.id === currentValue.id) ? previousValue : [...previousValue, currentValue];
            }, []).sort(sortAdverts).slice(0, options.advertBufferSize.advertRain)
            )]
        );

        setAdvertsWithVideo(prevState => {
            const incomingAdvertsWithVideo = incomingAdverts.filter(x => x.tvcUrl && !prevState.some(y => x.id === y.id));
            return [...prevState, ...incomingAdvertsWithVideo].sort(sortAdverts).slice(0, options.advertBufferSize.heroWithTvc)
        });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/stream?region=${options.region}`).then(res => receiveAdverts(res.data, true));

        hubConnection.on('stream', receiveAdverts);

        hubConnection.start().then(() => {
            console.log('Connected to stream hub');

            hubConnection.invoke('subscribe', options.region).then(() =>
                console.log(`Subscribed to stream hub region ${options.region}`));
        });
    }, []);

    const showVideoQueueSize = useDebugOption(options.debug.showVideoQueueSize);

    return (
        <div className="App">
            <StageOne visible={stage === 0}/>

            <div style={{ opacity: stagesWithRain.includes(stage) ? 1 : 0, transition: '1s' }}>
                <AdvertRain go={stagesWithRain.includes(stage)} adverts={adverts}/>
            </div>

            <Copy visible={!stages[stage].showHero && !!stages[stage].text[0]} {...stages[stage]} />

            <div style={{ opacity: stagesWithHero.includes(stage) && heroAd ? 1 : 0, transition: '1s'}} >
                <Hero visible={stagesWithHero.includes(stage) && heroAd} {...heroAd} />
            </div>

            {showVideoQueueSize && <div style={{
                position: 'fixed',
                right: 0,
                bottom: 0,
                padding: 20,
                fontSize: '5vw',
                opacity: 1,
                zIndex: 99999,
                backgroundColor: advertsWithVideo.length >= 5 ? 'lime' : advertsWithVideo.length > 2 ? 'yellow' : 'red'
            }}>
                {advertsWithVideo.length}
            </div>}

            {isUserFacing && <div
                style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    bottom: 0,
                    left: 0,
                    backgroundColor: 'transparent',
                    zIndex: 2147483647,
                    pointerEvents: 'all',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                    paddingBottom: 50,
                    paddingLeft: 50,
                    boxSizing: 'border-box'
                }}
            >
                <TellMeMoreButton />
            </div>}
        </div>
    );
}

export default App;
