import React from 'react';
import './styles.css';

function TellMeMoreButton() {
    return (
        <a href="https://adgile.com">
            <button className="tellMeMoreButton">Tell me more</button>
        </a>
    );
}

export default TellMeMoreButton;