export default {
    stages: [
        {id: 0, duration: 5000 , entryDelay: 0   , showHero: false, showRain: false, text: ['','']},
        {id: 1, duration: 30000, entryDelay: 2000, showHero: false, showRain: true , text: ['Optimise TV ad spend', 'with real-time AI']},
        {id: 2, duration: 30000, entryDelay: 0   , showHero: true , showRain: true , text: ['', '']},
        {id: 3, duration: 30000, entryDelay: 0   , showHero: false, showRain: true , text: ['TV insight at the', 'speed of digital']},
        {id: 4, duration: 30000, entryDelay: 0   , showHero: false, showRain: true , text: ['Live', 'now']},
        {id: 6, duration: 0    , entryDelay: 0   , showHero: false, showRain: false, text: ['Switch on at', 'adgile.media/futureoftv']},
    ],
    userFacingStages: [
        {id: 0, duration: 5000},
        {id: 1, duration: 10000},
        {id: 2, duration: 10000},
        {id: 3, duration: 10000},
        {id: 4, duration: 5000},
        {id: 6, duration: 0}
    ],
    showcaseLocation: {
        hostname: "adgile.media",
        pathname: "/showcase"
    },
    delayOverride: null,
    region: 'ca',
    apiUrl: {
        dev: 'http://localhost:5000',
        live: 'https://adgile-au-showcase-api.azurewebsites.net'
    },
    advertBufferSize: {
        advertRain: 15,
        heroWithTvc: 50
    },
    advertRain: {
        dropFrequency: 1200,
        columnCount: 4,
        columnPadding: 2,
        minAdvertWidthFactor: 0.5,
        advertSizeCount: 4,
        logoFrequency: 7,
        deleteAfter: 30,
        sizeAdvertsByRecency: {
            enabled: true,
            thresholds: [
                { floor: 0, ceiling: 10, widthFactor: 1 },
                { floor: 10, ceiling: 20, widthFactor: 0.7 },
                { floor: 20, ceiling: 30, widthFactor: 0.5 },
                { floor: 30, ceiling: null, widthFactor: 0.3 }
            ]
        },
        textStyle: {
            // ['bottom', 'overlay', 'below']
            position: 'below',
            // ['none', 'full', 'short']
            secondsAgo: 'short',
            showChannel: true,
            showLocation: true,
            // Recommended 1.2 for (bottom, full, true, false), 5 for (overlay, short, false, false)
            fontSize: 1.5,
            // Recommended for overlay
            shadedText: false
        }
    },
    heroVideoBlur: {
        thickness: 0,
        blurRadius: 10
    },
    heroVideoExpiry: 120,
    allowDebugInLive: false,
    enableDebug: false,
    debug: {
        showAdvertRainQueueSize: true,
        showAdvertId: true,
        isUserFacing: false,
        showVideoQueueSize: true
    }
};
