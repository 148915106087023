import React from 'react';
import Bolt from "../images/AdgileBolt_RGB_Red_HalfBlue.svg";
import {useMediaQuery} from "react-responsive";

function BoltHalfBlue() {
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    return (
        <img
            alt=""
            src={Bolt}
            style={{
                boxSizing: 'border-box',
                height: isPortrait ? '30vh' : '40vh'
            }} />
    );
}

export default BoltHalfBlue;