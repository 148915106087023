import React from 'react';
import LogoImage from '../images/AgileLogo_RGB_Swoosh_White_With_Tagline.png';

function Logo() {
    return (
        <div style={{
            position: 'fixed',
            height: '40vh',
            marginTop: '30vh',
            display: 'flex',
            justifyContent: 'center',
            width: '80vw',
            marginLeft: '10vw'
        }}>
            <img alt="" src={LogoImage} height={'100%'} style={{ maxWidth: '100%', objectFit: 'contain' }} />
        </div>
    );
}

export default Logo;