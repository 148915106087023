import { useState, useEffect, useCallback } from 'react';
import moment from "moment";
import useInterval from "@use-it/interval";

function useSecondsAgo(timestamp) {
    const [secondsAgo, setSecondsAgo] = useState(10);

    const updateSecondsAgo = useCallback(() =>
        setSecondsAgo(Math.round(moment.duration(moment().utc().diff(moment.utc(timestamp))).asSeconds())),
        [timestamp]);

    useEffect(() => updateSecondsAgo(), [timestamp, updateSecondsAgo]);
    useInterval(() => updateSecondsAgo(), 1000);

    return secondsAgo;
}

export default useSecondsAgo;