import React, { useState, useEffect, useRef } from 'react';
import SplitText from 'react-pose-text';

function Copy(props) {
    const { text, visible, entryDelay } = props;
    const previousVisible = usePrevious(visible);
    const [textPose, setTextPose] = useState("preEnterPose");
    
    const charPoses = {
        preEnterPose: {
            y: '-60vh',
            opacity: 0,
            transition: {
                duration: 0
            }
        },
        enter: {
            y: 0,
            opacity: 1,
            transition: () => {
                const multiplier = Math.round(Math.random() * 100);
                return ({
                    type: 'spring',
                    stiffness: 100,
                    damping: 17,
                    duration: 700,
                    delay: entryDelay ? multiplier * 10 + entryDelay : multiplier * 10,
                });
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 500
            }
        }
    };

    useEffect(() => {
        if(previousVisible) {
            if(visible) {
                setTextPose('enter');
            } else {
                setTextPose('exit');
            }
        } else {
            if(visible) {
                setTextPose('preEnterPose');
                setTimeout(() => setTextPose('enter'), 0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    

    return (
        <div style={{
            fontSize: '6vw',
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}
        >
          <div style={{
              background: visible ? 'linear-gradient(0deg, hsla(242,50.9%,11.2%,0.0) 0%, hsla(242,50.9%,11.2%,0.3) 25%, hsla(242,50.9%,11.2%,0.5) 35%, hsla(242,50.9%,11.2%,0.65) 48%, hsla(242,50.9%,11.2%,0.7) 55%, hsla(242,50.9%,11.2%,0.7) 70%, hsla(242,50.9%,11.2%,0.5) 80%, hsla(242,50.9%,11.2%,0.25) 90%, hsla(242,50.9%,11.2%,0.0) 100%)' : 'transparent',
              width: '100%',
              height: '30vh',
              padding: '10vh 0vw 4vh 0vw', 
              zIndex: 1,
              transition: '0.5s background',
          }}>
              <span style={{ color: '#ffffff', zIndex: 3 }}>
                <SplitText initialPose="preEnterPose" pose={textPose} charPoses={charPoses}>{text[0]}</SplitText>&nbsp;
              </span>
              <span style={{ color: '#ff6f61', zIndex: 3 }} className={'shimmer'}>
                <SplitText initialPose="preEnterPose" pose={textPose} charPoses={charPoses}>{text[1]}</SplitText>
              </span>
            </div>
        </div>
    );
}

// From: https://usehooks.com/usePrevious/
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export default Copy;
