import options from "../options";

function useDebugOption(option) {
    const isDev = process.env.NODE_ENV === 'development';

    if(!isDev && !options.allowDebugInLive)
        return false;

    return option && options.enableDebug;
}

export default useDebugOption;